import React from "react";
import Button from "@mui/material/Button";

export default function CustomMenuList(props) {
  return (
    <div>
      <Button
        onClick={() => {
          if (props.target == "_blank") {
            window.open(props.href, props.target);
          } else {
            window.location.href = props.href;
          }
        }}
        style={{ color: "#1e3d52", fontWeight: "bold" }}
      >
        {props.title}
      </Button>
    </div>
  );
}
